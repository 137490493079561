<template>
  <div class="main">
    <VueSlickCarousel class="main-auctions" v-bind="settingsHeader">
      <div class="main-auction">
<!--        <img v-if="year1Image" :src="'https://arweave.net/' + year1Image" alt="1 YEAR Auction" class="main-auction__image">-->
<!--        <img v-else src="../../public/auction-1.webp" alt="1 YEAR Auction" class="main-auction__image">-->

          <img :src="require(`../../public/Lae-backup/pics/${year1Image}.png`)" alt="1 YEAR Auction" class="main-auction__image">
        <div class="main-auction__body">
          <h2 class="main-auction__name"><span>1 YEAR</span> Auction</h2>
          <div class="main-auction__timer">
            <Timer :finish="auctionFinished1Year" @auctionFinish="(type) => auctionFinished1Year = type " :date="changeDate('1years')" />
          </div>
          <div v-if="auctionFinished1Year" class="main-auction__btns">
            <router-link :to="{ name: 'Explore' }" class="main-auction__bid">
              <span>{{ $t("main.explore_nfts") }}</span>
            </router-link>
          </div>
          <div v-else class="main-auction__btns">
            <router-link :to="{ name: 'Auction', params: {years: '1'} }" class="main-auction__bid">
              <span>{{ $t("main.make_bid") }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="main-auction" key="2">
<!--        <img v-if="year10Image" :src="'https://arweave.net/' + year10Image" alt="10 YEAR Auction" class="main-auction__image">-->
<!--        <img v-else src="../../public/auction-2.webp" alt="10 YEAR Auction" class="main-auction__image">-->
        <img :src="require(`../../public/Lae-backup/pics/${year10Image}.png`)" alt="1 YEAR Auction" class="main-auction__image">
        <div class="main-auction__body">
          <h2 class="main-auction__name"><span>10 YEAR</span> Auction</h2>
          <div class="main-auction__timer">
            <Timer :finish="auctionFinished10Year" @auctionFinish="(type) => auctionFinished10Year = type " :date="changeDate('10years')"/>
          </div>
          <div v-if="auctionFinished10Year" class="main-auction__btns">
            <router-link :to="{ name: 'Explore' }" class="main-auction__bid">
              <span>{{ $t("main.explore_nfts") }}</span>
            </router-link>
          </div>
          <div v-else class="main-auction__btns">
            <router-link :to="{ name: 'Auction', params: {years: '10'} }" class="main-auction__bid">
              <span>{{ $t("main.make_bid") }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="main-auction" key="3">
<!--        <img v-if="year100Image" :src="'https://arweave.net/' + year100Image" alt="100 YEAR Auction" class="main-auction__image">-->
<!--        <img v-else src="../../public/auction-3.webp" alt="100 YEAR Auction" class="main-auction__image">-->
        <img :src="require(`../../public/Lae-backup/pics/${year100Image}.png`)" alt="1 YEAR Auction" class="main-auction__image">
        <div class="main-auction__body">
          <h2 class="main-auction__name"><span>100 YEAR</span> Auction</h2>
          <div class="main-auction__timer">
            <Timer :finish="auctionFinished100Year" @auctionFinish="(type) => auctionFinished100Year = type " :date="changeDate('100years')"/>
          </div>
          <div v-if="auctionFinished100Year" class="main-auction__btns">
            <router-link :to="{ name: 'Explore' }" class="main-auction__bid">
              <span>{{ $t("main.explore_nfts") }}</span>
            </router-link>
          </div>
          <div v-else class="main-auction__btns">
            <router-link :to="{ name: 'Auction', params: {years: '100'} }" class="main-auction__bid">
              <span>{{ $t("main.make_bid") }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
  import moment from "moment"
  import Timer from "./Timer.vue";
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  // import { gsap } from 'gsap';
  // import { CSSPlugin } from 'gsap/CSSPlugin'
  // gsap.registerPlugin(CSSPlugin);

  const setings = {
    "arrows": false,
    "infinite": false,
    "dots": false,
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "responsive": [
      {
        "breakpoint": 1250,
        "settings": {
          "slidesToShow": 2.2,
          "slidesToScroll": 0.8,
        }
      },
      {
        "breakpoint": 950,
        "settings": {
          "slidesToShow": 1.5,
          "slidesToScroll": 0.75,
        }
      },
      {
        "breakpoint": 768,
        "settings": {
          "slidesToShow": 1.2,
          "slidesToScroll": 0.9,
        }
      },
      {
        "breakpoint": 460,
        "settings": {
          "slidesToShow": 1.1,
          "slidesToScroll": 0.975,
        }
      },
      {
        "breakpoint": 380,
        "settings": {
          "slidesToShow": 1.05,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 360,
        "settings": {
          "slidesToShow": 0.98,
          "slidesToScroll": 1,
        }
      }
    ]
  }

  import VueSlickCarousel from 'vue-slick-carousel'
  export default {
    name: "AuctionCard",
    components: {
      Timer,
      VueSlickCarousel
    },
    data () {
      return {
        auctionFinished100Year: false,
        auctionFinished10Year: false,
        auctionFinished1Year: false,
        images1Year: [],
        images10Year: [],
        setTimeout1Year: null,
        setTimeout10Year: null,
        seasonNumber: 1,
        year1Image: 100,
        year10Image: 10,
        year100Image: 1,
        season: 'spring',
        settingsHeader: setings
      }
    },
    mounted () {
      this.getSeason()
      this.getImage1Year()
      this.getImage10Year()
      this.getImage100Year()
    },
    methods: {
      changeDate (value) {
        if (!value) return ''
        return localStorage.getItem(`${value}`)
      },
      getImage1Year () {
        this.update1YearImage(1)
      },
      getImage10Year () {
        this.update10YearImage(1)
      },
      getImage100Year () {
        this.year100Image = this.seasonNumber
      },
      update1YearImage(number) {
        if (this.setTimeout1Year) {
          clearInterval(this.setTimeout1Year)
        }
        this.setTimeout1Year =  setTimeout(() => {
          const random = Math.floor(Math.random() * 100)
          if (number) {
            if (number < 10) {
              number = '0' + number
            }
            this.year1Image = this.seasonNumber+ '' + number
          }
          this.update1YearImage(random)
        }, 5000)
      },
      update10YearImage(number) {
        if (this.setTimeout10Year) {
          clearInterval(this.setTimeout10Year)
        }
       this.setTimeout10Year = setTimeout(() => {
          const random = Math.floor(Math.random() * 10)
          if (number) {
            this.year10Image = this.seasonNumber+ '' + number
          }
          this.update10YearImage(random)
        }, 5000)
      },
      getSeason() {
        let nowDate = moment().format("M");
        if (nowDate === '12' || nowDate === '1' || nowDate === '2') {
          this.season = 'winter'
          this.seasonNumber = 1
        } else if (nowDate === '3' || nowDate === '4' || nowDate === '5') {
          this.season = 'spring'
          this.seasonNumber = 2
        } else if (nowDate === '6' || nowDate === '7' || nowDate === '8') {
          this.season = 'summer'
          this.seasonNumber = 3
        } else {
          this.season = 'autumn'
          this.seasonNumber = 4
        }
        this.year1Image = this.seasonNumber + '00'
        this.year10Image = this.seasonNumber + '0'
        this.year100Image = this.seasonNumber
      },
    }
  }
</script>

<style scoped lang="scss">
  .main {
    width: 100%;
    display: flex;
    justify-content: center;

    &-auctions {
      width: 100%;
      justify-content: center;
    }
    &-auction {
      flex-basis: 30%;
      background: #F9F9F9;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      overflow: hidden;
      &__image {
        width: 100%;
        height: 405px;
        object-fit: cover;
      }
      &__body {
        padding: 0 20px 16px;
      }
      &__name {
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #000000;
        margin: 0;
        padding: 16px 0;
        text-align: center;
        font-weight: normal;
        span {
          font-weight: bold;
        }
      }
      &__timer {
        padding: 12px 0;
        border-top: 1px solid #E1E1E1;
        border-bottom: 1px solid #E1E1E1;
      }
      &__btns {
        padding: 16px 24px 0;
        display: flex;
        justify-content: center;
      }
      &__bid {
        display: block;
        padding: 10px 26px;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        background: #ED7D37;
        border-radius: 5px;
        white-space: nowrap;
        text-decoration: none;
        &:hover {
          box-shadow: 0px 0px 10px rgba(237, 125, 55, 0.5);
          padding: 12px 36px;
          margin: -2px -10px -2px;
        }
      }
    }

  }

</style>
