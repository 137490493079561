var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main"
  }, [_c('VueSlickCarousel', _vm._b({
    staticClass: "main-auctions"
  }, 'VueSlickCarousel', _vm.settingsHeader, false), [_c('div', {
    staticClass: "main-auction"
  }, [_c('img', {
    staticClass: "main-auction__image",
    attrs: {
      "src": require("../../public/Lae-backup/pics/".concat(_vm.year1Image, ".png")),
      "alt": "1 YEAR Auction"
    }
  }), _c('div', {
    staticClass: "main-auction__body"
  }, [_c('h2', {
    staticClass: "main-auction__name"
  }, [_c('span', [_vm._v("1 YEAR")]), _vm._v(" Auction")]), _c('div', {
    staticClass: "main-auction__timer"
  }, [_c('Timer', {
    attrs: {
      "finish": _vm.auctionFinished1Year,
      "date": _vm.changeDate('1years')
    },
    on: {
      "auctionFinish": function auctionFinish(type) {
        return _vm.auctionFinished1Year = type;
      }
    }
  })], 1), _vm.auctionFinished1Year ? _c('div', {
    staticClass: "main-auction__btns"
  }, [_c('router-link', {
    staticClass: "main-auction__bid",
    attrs: {
      "to": {
        name: 'Explore'
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.explore_nfts")))])])], 1) : _c('div', {
    staticClass: "main-auction__btns"
  }, [_c('router-link', {
    staticClass: "main-auction__bid",
    attrs: {
      "to": {
        name: 'Auction',
        params: {
          years: '1'
        }
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.make_bid")))])])], 1)])]), _c('div', {
    key: "2",
    staticClass: "main-auction"
  }, [_c('img', {
    staticClass: "main-auction__image",
    attrs: {
      "src": require("../../public/Lae-backup/pics/".concat(_vm.year10Image, ".png")),
      "alt": "1 YEAR Auction"
    }
  }), _c('div', {
    staticClass: "main-auction__body"
  }, [_c('h2', {
    staticClass: "main-auction__name"
  }, [_c('span', [_vm._v("10 YEAR")]), _vm._v(" Auction")]), _c('div', {
    staticClass: "main-auction__timer"
  }, [_c('Timer', {
    attrs: {
      "finish": _vm.auctionFinished10Year,
      "date": _vm.changeDate('10years')
    },
    on: {
      "auctionFinish": function auctionFinish(type) {
        return _vm.auctionFinished10Year = type;
      }
    }
  })], 1), _vm.auctionFinished10Year ? _c('div', {
    staticClass: "main-auction__btns"
  }, [_c('router-link', {
    staticClass: "main-auction__bid",
    attrs: {
      "to": {
        name: 'Explore'
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.explore_nfts")))])])], 1) : _c('div', {
    staticClass: "main-auction__btns"
  }, [_c('router-link', {
    staticClass: "main-auction__bid",
    attrs: {
      "to": {
        name: 'Auction',
        params: {
          years: '10'
        }
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.make_bid")))])])], 1)])]), _c('div', {
    key: "3",
    staticClass: "main-auction"
  }, [_c('img', {
    staticClass: "main-auction__image",
    attrs: {
      "src": require("../../public/Lae-backup/pics/".concat(_vm.year100Image, ".png")),
      "alt": "1 YEAR Auction"
    }
  }), _c('div', {
    staticClass: "main-auction__body"
  }, [_c('h2', {
    staticClass: "main-auction__name"
  }, [_c('span', [_vm._v("100 YEAR")]), _vm._v(" Auction")]), _c('div', {
    staticClass: "main-auction__timer"
  }, [_c('Timer', {
    attrs: {
      "finish": _vm.auctionFinished100Year,
      "date": _vm.changeDate('100years')
    },
    on: {
      "auctionFinish": function auctionFinish(type) {
        return _vm.auctionFinished100Year = type;
      }
    }
  })], 1), _vm.auctionFinished100Year ? _c('div', {
    staticClass: "main-auction__btns"
  }, [_c('router-link', {
    staticClass: "main-auction__bid",
    attrs: {
      "to": {
        name: 'Explore'
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.explore_nfts")))])])], 1) : _c('div', {
    staticClass: "main-auction__btns"
  }, [_c('router-link', {
    staticClass: "main-auction__bid",
    attrs: {
      "to": {
        name: 'Auction',
        params: {
          years: '100'
        }
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.make_bid")))])])], 1)])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }